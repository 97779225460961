
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
}));

export const StyledHeader = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
}));

export const StyledIcons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  '& > *': {
    margin: theme.spacing(1),
    color: theme.palette.grey[500],
    transition: 'color 0.3s ease-in-out', // add this line for a smooth color transition
    '&:hover': {
      color: theme.palette.grey[700], // change the color on hover
    },
  },

}));