import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { StyledButton, StyledContainer } from './styles';

const NotFound: React.FC = () => {
    useEffect(() => {
        document.title = 'Page Not Found';
    }, []);

    return (
        <StyledContainer maxWidth="sm">
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Page Not Found
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
                The page you're looking for is hiding from us. We're currently on a search mission to find it.
            </Typography>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <StyledButton variant="contained" startIcon={<Search />}>
                    Take Me Home
                </StyledButton>
            </Link>
        </StyledContainer>
    );
};
export default NotFound;
