import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { GitHub, LinkedIn } from '@mui/icons-material'
import { StyledContainer, StyledContent, StyledHeader, StyledIcons } from './styles';



const UnderConstructionPage: React.FC = () => {
    useEffect(() => {
        document.title = 'Site Under Construction';
    }, []);

    const linkedinUrl = 'https://www.linkedin.com/in/alpeshyadav';
    const githubUrl = 'https://github.com/alpeshyadav';

    return (
        <StyledContainer>
            <StyledHeader>
                <Typography variant="h3">Site Under Construction</Typography>
            </StyledHeader>
            <StyledContent>
                <Typography variant="body1">
                    Thank you for visiting my website. I am currently working on it and it will be available soon. In the meantime, please feel free to connect with me on LinkedIn.
                </Typography>
                <StyledIcons>
                    <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                        <LinkedIn sx={{ fontSize: '2.5rem' }} />
                    </a>
                    <a href={githubUrl} target="_blank" rel="noopener noreferrer">
                        <GitHub sx={{ fontSize: '2.5rem' }} />
                    </a>
                </StyledIcons>
            </StyledContent>
        </StyledContainer>
    );
};

export default UnderConstructionPage;
