import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NotFound, UnderConstructionPage } from './components';


const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<UnderConstructionPage />} />
          {/* other routes */}
          <Route path="*" element={<NotFound />} />

        </Routes>
      </Router>
    </div>
  );
};

export default App;
