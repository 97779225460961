import { styled } from '@mui/material/styles'
import { Button, Container } from '@mui/material'


export const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(2),
    '&:hover': {
        backgroundColor: theme.palette.grey[500],
    },
}));